import { Link } from "@inertiajs/react";

export default function InlineLink({ className = "", children, ...props }) {
    return (
        <Link
            {...props}
            className={
                "inline-flex font-medium pl-1 text-outragous-orange hover:underline hover:text-dark-orange transition duration-150 ease-in-out " +
                className
            }
        >
            {children}
        </Link>
    );
}
